.B{
  stroke-linecap:round
}

.C{
  stroke-linejoin:round
}

.D{
  stroke-linejoin:miter
}

.E{
  stroke-width:.716
}